export type ThemeColorName = 'dark';

export interface IThemeColors {
  name: ThemeColorName;
  backgroundColor: string;
  backgroundColor2: string;
  textColor: string;
  primary: string;
  secondary: string;
  invert: string;
  invert2: string;
  highlight: string;
}

export const colors = {
  pearl: '#FAFAFA',
  lavender: '#FCECEF',
  flamingo: '#F3A1AE',
  hotpink: '#DA315C',
  coal: '#111111',
  midnight: '#000000',
  lime: '#E4FF77',
};

export const darkTheme: IThemeColors = {
  name: 'dark',
  backgroundColor: colors.coal,
  backgroundColor2: colors.midnight,
  textColor: colors.pearl,
  primary: colors.hotpink,
  secondary: colors.flamingo,
  invert: colors.lavender,
  invert2: colors.pearl,
  highlight: colors.lime,
};

// TODO fonts?
