import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAQ69MJFkXQ_698O-KoLOFccPt1Ywddmk0",
  authDomain: "snipps-website.firebaseapp.com",
  projectId: "snipps-website",
  storageBucket: "snipps-website.appspot.com",
  messagingSenderId: "125270198536",
  appId: "1:125270198536:web:be93b2421e94c481eb04bd",
  measurementId: "G-95QQB346CP"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
