import * as tf from '@tensorflow/tfjs';
import * as deeplab from '@tensorflow-models/deeplab';
import icon from '../assets/images/icon.png';

// MIT http://rem.mit-license.org
function trimCanvas(c) {
  var ctx = c.getContext('2d'),
      copy = document.createElement('canvas').getContext('2d'),
      pixels = ctx.getImageData(0, 0, c.width, c.height),
      l = pixels.data.length,
      i,
      bound = {
          top: null,
          left: null,
          right: null,
          bottom: null
      },
      x, y;
  
  // Iterate over every pixel to find the highest
  // and where it ends on every axis ()
  for (i = 0; i < l; i += 4) {
      if (pixels.data[i + 3] !== 0) {
          x = (i / 4) % c.width;
          y = ~~((i / 4) / c.width);

          if (bound.top === null) {
              bound.top = y;
          }

          if (bound.left === null) {
              bound.left = x;
          } else if (x < bound.left) {
              bound.left = x;
          }

          if (bound.right === null) {
              bound.right = x;
          } else if (bound.right < x) {
              bound.right = x;
          }

          if (bound.bottom === null) {
              bound.bottom = y;
          } else if (bound.bottom < y) {
              bound.bottom = y;
          }
      }
  }
  
  // Calculate the height and width of the content
  var trimHeight = bound.bottom - bound.top,
      trimWidth = bound.right - bound.left,
      trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);

  copy.canvas.width = trimWidth;
  copy.canvas.height = trimHeight;
  copy.putImageData(trimmed, 0, 0);

  // Return trimmed canvas
  return copy.canvas;
}

export const getImageDimensions = async (url) => {
  const image = await loadImage(url);
  
  return { width: image.width, height: image.height };
}

export const trimImage = async (image: HTMLImageElement) => {
  // create an off-screen canvas
  var canvas = document.createElement('canvas'),
      ctx = canvas.getContext('2d');

  // set its dimension to target size
  canvas.width = image.width;
  canvas.height = image.height;

  // draw source image into the off-screen canvas:
  ctx.drawImage(image, 0, 0, image.width, image.height);

  const trimmedCanvas = trimCanvas(canvas);
  canvas.remove();

  const result = await loadImage(trimmedCanvas.toDataURL());

  trimmedCanvas.remove();
  return result;
}

export const loadImage = async (source: string) => {
  var image = new Image();
  image.src = source;
  image.crossOrigin = 'Anonymous';

  await new Promise((resolve) => { image.addEventListener('load', resolve); }); // wait for load

  return image;
}

export const imageDataToImage = async (imageData: ImageData) => {
  var canvas = document.createElement('canvas');
  var ctx = canvas.getContext('2d');

  canvas.width = imageData.width;
  canvas.height = imageData.height;

  ctx.putImageData(imageData, 0, 0);

  const image = await loadImage(canvas.toDataURL());

  canvas.remove();
  return image;
}

export const resizeImage = (imageData, width, height) => {
  // create an off-screen canvas
  var canvas = document.createElement('canvas'),
      ctx = canvas.getContext('2d');

  // set its dimension to target size
  canvas.width = width;
  canvas.height = height;

  // draw source image into the off-screen canvas:
  ctx.drawImage(imageData, 0, 0, width, height);

  // encode image to data-uri with base64 version of compressed image
  const result = ctx.getImageData(0, 0, canvas.width, canvas.height, { })

  canvas.remove();

  return result;
}

export const resizeCanvas = async (canvas: HTMLCanvasElement, context, width: number, height: number) => {
  const image = await loadImage(canvas.toDataURL());

  canvas.width = width;
  canvas.height = height;

  context.drawImage(image, 0, 0);
}

export const removeBackgroundFromImage = async (image) => {
  /* load image */
  let img = await loadImage(image);

  /* draw on canvas */
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.height = img.height;
  canvas.width = img.width;

  ctx.drawImage(
    img, 
    canvas.width / 2 - img.width / 2,
    canvas.height / 2 - img.height / 2,
);

  /* setup model */
  tf.getBackend();
  const model = await deeplab.load({ base: 'pascal', quantizationBytes: 4 });

  /* image segmentation */
  const result = await model.segment(img);

  /* draw segmented */
  const { data: imgData } = ctx.getImageData(0, 0, canvas.width, canvas.height)
  const newImg = ctx.createImageData(canvas.width, canvas.height)
  const newImgData = newImg.data

  const segmentationImageData = new ImageData(result.segmentationMap, result.width, result.height);;
  const segmentationImage = await imageDataToImage(segmentationImageData);
  const scaledImageData = resizeImage(segmentationImage, img.width, img.height);

  for (var i = 0; i < scaledImageData.data.length; i = i + 4) {
    const s = scaledImageData.data;

    if (!(s[i] === 0 && s[i + 1] === 0 && s[i + 2] === 0)) {
      newImgData[i] = imgData[i]
      newImgData[i + 1] = imgData[i + 1]
      newImgData[i + 2] = imgData[i + 2]
      newImgData[i + 3] = imgData[i + 3]
    }
  }

  const noBgImage = await imageDataToImage(newImg);
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(
    noBgImage, 
    canvas.width / 2 - noBgImage.width / 2,
    canvas.height / 2 - noBgImage.height / 2,
  );

  const dataURL = canvas.toDataURL();

  canvas.remove();

  return dataURL;
}

export const applyTextToImage = async (image, text, color) => {
  /* load image */
  let img = await loadImage(image);

  /* trim image */
  img = await trimImage(img);

  /* draw on canvas */
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.width = (img.height > img.width ? img.height : img.width) * 1.2;
  canvas.height = img.height;
  const canvasAvg = (canvas.width + canvas.height) / 2;

  ctx.drawImage(
    img,
    (canvas.width / 2) - (img.width / 2),
    (canvas.height / 2) - (img.height / 2),
  );

  let fontSize, calculated;
  fontSize = (canvas.height + canvas.width) / 4;
  do {
    fontSize -= 5;
    ctx.font = `${fontSize}px capriola`;
    ctx.textAlign = 'center';
    calculated = ctx.measureText(text);
  }
  while (calculated.width > (canvas.width * 0.9));

  const textX = canvas.width / 2;
  const textY = canvas.height * 0.95;

  const speakerIcon = await loadImage(icon);
  const speakerLoc = {
    y: (textY - fontSize / 4),
    x: textX - (calculated.width / 2),
    width: text.length ? fontSize * 1.5 : canvasAvg / 4,
    height: text.length ? fontSize * 1.5 : canvasAvg / 4,
  }

  if ((speakerLoc.y + speakerLoc.height) > canvas.height) {
    var newheight = speakerLoc.y + speakerLoc.height;
    await resizeCanvas(canvas, ctx, canvas.width, newheight);
  }

  ctx.font = `${fontSize}px capriola`;
  ctx.textAlign = 'center';

  ctx.lineCap = "round";
  ctx.lineJoin = "round";
  ctx.strokeStyle = '#ffffff';
  ctx.lineWidth = (fontSize / 10) * 5;
  ctx.strokeText(text, textX, textY);

  ctx.strokeStyle = '#000000';
  ctx.lineWidth = (fontSize / 10) * 3;
  ctx.strokeText(text, textX, textY);

  ctx.strokeStyle = color;
  ctx.lineWidth = fontSize / 10;
  ctx.strokeText(text, textX, textY);

  ctx.fillStyle = color;
  ctx.fillText(text, textX, textY);

  ctx.drawImage(speakerIcon, speakerLoc.x, speakerLoc.y, speakerLoc.width, speakerLoc.height);

  const trimmed = trimCanvas(canvas);
  canvas.remove();
  const dataURL = trimmed.toDataURL();
  trimmed.remove();
  return dataURL;
}