export async function urlToBase64(url: string): Promise<string> {
  const blob = await filePathToBlob(url, false);
  return await blobToBase64(blob);
}

export async function blobToBase64(blob: Blob): Promise<string> {
  var reader = new FileReader();

  const promise = new Promise((resolve) => {
    reader.onload = function() {
      resolve(reader.result);
    };
  });

  reader.readAsDataURL(blob);

  return (await promise) as string;
}

export async function filePathToBlob(path: string, proxy: boolean): Promise<File> {
  var request = new XMLHttpRequest();

  const url = proxy ? wrapURLWithPasten(path) : path;

  request.open('GET', url, true);
  request.responseType = 'blob';
  const promise = new Promise((resolve) => {
    request.onload = function() {
      resolve(request.response);
    };
  });

  request.send();

  return (await promise) as File;
}

export function getFileExtention(path: string) {
  if (path.includes('base64')) {
    return path.split(';')[0].split('/')[1];
  }
  else {
    const split = path.split('.');
    return split[split.length - 1];
  }
}

export function wrapURLWithPasten(url): string {
  const u = new URL(url);
  u.host = `${u.host.replace('www.', '')}.pasten.link`;
  return u.toString().replace('http://', 'https://');
}