import { getAuth } from 'firebase/auth';
import * as fstorage from 'firebase/storage';
import { addDoc, collection, doc, getFirestore, serverTimestamp } from 'firebase/firestore';
import React, {useState, useEffect, useMemo} from 'react';
import { getUsername } from './user';
import { v4 as uuidv4 } from 'uuid';
import { config } from 'src/config';
import { getFileExtention } from './utils';
import { getImageDimensions } from './image';

export function getPublicDownloadURL(path) {
  /* NOTE: this is for public files, that can be accessed using HTTP for fast retrieval */
  return `http://storage.googleapis.com/${config.storage.bucket.public.name}/${path}`;
}

export const createSnipp = async (
  title: string,
  description: string,
  audioFile: string,
  audioDuration: number,
  stickerFile: string,
  stickerProps: object,
  tags: Array<string>,
  audioSourceType: string,
  audioSourceUrl: string,
  audioSourceMetadata: object,
  imageSourceType: string,
  imageSourceUrl: string,
  imageSourceMetadata: object,
  creatorISOCountryCode: string,
  creatorLanguages: Array<string>,
  detectedLanguages: Array<string>,
) => {
  const auth = getAuth();
  const storage = fstorage.getStorage(undefined, 'gs://snipps');
  const uuid = uuidv4();

  // Step A - Upload Image
  const imageExt = getFileExtention(stickerFile);
  const imagePath = `user/${auth.currentUser.uid}/${uuid}.${imageExt}`;
  const imageRef = fstorage.ref(storage, imagePath);
  const stickerUrl = getPublicDownloadURL(imagePath);

  // Step B - Upload Audio
  const audioExt = getFileExtention(audioFile);
  const audioPath = `user/${auth.currentUser.uid}/${uuid}.${audioExt}`;
  const audioRef = fstorage.ref(storage, audioPath);
  const audioUrl = getPublicDownloadURL(audioPath);

  await Promise.allSettled([
    fstorage.uploadString(imageRef, stickerFile, 'data_url'),
    fstorage.uploadString(audioRef, audioFile, 'data_url'),
  ]);

  // Step C - Create Snipp
  const username = await getUsername();
  const firestore = getFirestore();
  const creator = doc(collection(firestore, 'user'), username);
  const snipp = collection(firestore, 'snipp');
  const { width: stickerImageWidth, height: stickerImageHeight } = await getImageDimensions(imageSourceUrl);
  const result = await addDoc(snipp, {
    creator,
    title,
    description,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
    audioUrl,
    duration: audioDuration * 1000,
    stickerImageUrl: stickerUrl,
    stickerImageWidth,
    stickerImageHeight,
    stickerProps,
    stickerVersion: 1.0,
    audioVersion: 1.0,
    tags,
    audioSourceType,
    audioSourceUrl,
    audioSourceMetadata,
    imageSourceType,
    imageSourceUrl,
    imageSourceMetadata,
    playCounter: 0,
    shareCounter: 0,
    videoCounter: 0,
    creatorISOCountryCode,
    creatorLanguages,
    detectedLanguages,
  });
}