import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { useCurrentUser } from 'src/libs/user';
import { CircularProgress, InputAdornment, TextField } from '@mui/material';
import { CgHashtag } from 'react-icons/cg';
import { collection, doc, getDoc, getFirestore, query, setDoc, where } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { StyledClaimUsernameButton, StyledSetupContainer } from './setup.styled';

export function Setup({}) {
  const user = useCurrentUser();
  const [username, setUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const [available, setAvailable] = useState(false);
  
  useEffect(() => {
    if (username.length < 3) {
      setAvailable(false);
      return;
    }

    var cancel = false;

    const firestore = getFirestore();
    const users = collection(firestore, "user");

    setLoading(true);

    getDoc(doc(users, username))
    .then((snapshot) => {
      if (cancel) return;

      setAvailable(!snapshot.exists());
    })
    .finally(() => {
      setLoading(false);
    });

    return () => {
      cancel = true;
      setLoading(false);
      setAvailable(false);
    }
  }, [username]);

  const claimUsername = useCallback(() => {
    if (loading || !available || !user) {
      return;
    }

    setLoading(true);
    setAvailable(false);

    const functions = getFunctions();
    const claimUsername = httpsCallable(functions, 'claimUsername')
    claimUsername({ username, })
    .catch((e) => {
      setAvailable(true);
      alert(e.message);
    })
    .finally(() => {
      setLoading(false);
    });

  }, [loading, available, user, username]);

  return (
    <StyledSetupContainer data-flex data-container>
      <h1>Claim your username</h1>
      <br/>
      <br/>
      <TextField
        variant='outlined'
        value={username}
        onChange={(event) => {
          const regex = /^([a-z0-9_]){0,16}$/i;
          if (event.target.value === '' || regex.test(event.target.value)) {
            setUsername(event.target.value);
          }
        }}
        autoComplete='off'
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CgHashtag />
            </InputAdornment>
          ),
        }}
      />
      <StyledClaimUsernameButton data-flex className='button claim-username-button' data-disabled={!available} onClick={claimUsername}>
        { loading ?
          <CircularProgress size={24} color='secondary' />
        :
          <div>Continue</div>
        }
      </StyledClaimUsernameButton>
    </StyledSetupContainer>
  );
}