import { CircularProgress } from '@mui/material';
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { StyledSnipp, StyledSnippImage } from './snipp.styled';

export function Snipp({ image, sound }) {
  const ref = useRef(null);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    let cancel = false;
    ref.current.addEventListener("ended", () => {
      if (cancel) return;
      setPlaying(false);
    });

    return () => {
      cancel = true;
    }
  }, []);

  const handleClick = useCallback(() => {
    setPlaying(true);
    ref.current.currentTime = 0;
    ref.current.play();
  }, []);

  return (
    <StyledSnipp data-playing={playing} onClick={handleClick}>
      { image ? 
        <StyledSnippImage data-playing={playing} src={image} alt='snipp' />
      : 
        <CircularProgress className='snipp' />
      }
      <audio ref={ref} id="audio" src={sound}/>
    </StyledSnipp>
  );
}