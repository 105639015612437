import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { MdPlayArrow, MdSearch, MdUploadFile } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { Snipp } from 'src/components/snipp/snipp';
import { Autocomplete, Button, Chip, CircularProgress, FormControlLabel, Switch, TextField } from '@mui/material';
import { createSnipp } from 'src/libs/snipp';
import { applyTextToImage, removeBackgroundFromImage } from 'src/libs/image';
import { config } from 'src/config';
import { getAudioDuration, useAudioDuration } from 'src/libs/sound';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { StyledCreateAddStickerInputSection, StyledCreateAddStickerInputSectionSpan, StyledCreateContainer, StyledCreateContinueButton, StyledCreateUploadButton } from './create.styled';

export const removeImageBackground = async (url: string): Promise<any> => {
  const functions = getFunctions();
  const removeBG = httpsCallable(functions, 'removeImageBackground');
  const result = await removeBG({ url, });

  return result.data;
}

export const searchImages = async (query: string): Promise<any> => {
  const functions = getFunctions();
  const searchGoogle = httpsCallable(functions, 'searchGoogle');
  const result = await searchGoogle({ query, });

  return (result.data as any).images_results;
}

export const searchSounds = async (query: string): Promise<any> => {
  const functions = getFunctions();
  const searchSoundsFunc = httpsCallable(functions, 'searchSounds');
  const result = await searchSoundsFunc({ query, });

  return result.data;
}

export function useCountryCode() {
  const [cc, setCc] = useState<string>(null);

useEffect(() => {
    var cancel = false;

    fetch('https://api.freegeoip.app/json/?apikey=74f62db0-79fc-11ec-8574-15fad30642a7')
    .then((result) => {
      result.json()
      .then((json) => {
        if (cancel) return;
        setCc(json.country_code);
      })
      .catch(() => setCc('UNKNOWN'));
    })
    .catch(() => setCc('UNKNOWN'));

    return () => {
      cancel = true;
    }
  }, []);

  return cc;
}

export function useFileAsBase64(file: File | string, proxy: boolean) {
  const [b64, setB64] = useState(null);

  useEffect(() => {
    var cancel = false;
    const reader = new FileReader();

    reader.addEventListener("load", function () {
      if (cancel) return;
      // convert image file to base64 string
      setB64(reader.result);
    }, false);
  
    if (file) {
      if (file instanceof Blob) {
        reader.readAsDataURL(file);
      }
      else {
        const urlToBase64 = httpsCallable(getFunctions(), 'urlToBase64');

        urlToBase64({ url: file.replace('http://', 'https://') })
        .then(({ data: base64 }) => {
          setB64(base64);
        });
      }
    }

    return () => {
      cancel = true;
    };
  }, [file, proxy]);

  return b64;
}

export function Create({}) {
  const [stage1Result, setStage1Result] = useState(null);

  return (
    !stage1Result ? (
      <CreateStage1 onResult={setStage1Result} />
    ) : <CreateStage2 sticker={stage1Result.sticker} sound={stage1Result.sound} />
  );
}

function CreateStage2({ sticker, sound }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [tags, setTags] = useState<string[]>([]);
  const duration = useAudioDuration(sound.url);

  const [errorTitle, setErrorTitle] = useState(null);
  const [errorDescription, setErrorDescription] = useState(null);
  const [errorTags, setErrorTags] = useState(null);

  const countryCode = useCountryCode();
  const creatorLanguages = useMemo(() => [...navigator.languages], []);
  const detectedLanguages = useMemo(() => [], []); // TODO

  const handleSubmit = useCallback(() => {
    setErrorTitle(null);
    setErrorDescription(null);
    setErrorTags(null);
    var cancel = false;

    if (title.length < 3) {
      setErrorTitle('Should contain at least 3 characters');
      cancel = true;
    }

    if (tags.length < 3) {
      setErrorTags('Should contain at least 3 tags');
      cancel = true;
    }

    if (cancel) {
      return;
    }

    setLoading(true);

    createSnipp(
      title,
      description,
      sound.url,
      duration ?? 0, // TODO: add duration
      sticker.url,
      sticker.props, // TODO: add props
      tags,
      sound.sourceType,
      sound.sourceUrl,
      sound.sourceMetadata,
      sticker.sourceType,
      sticker.sourceUrl,
      sticker.sourceMetadata,
      countryCode,
      creatorLanguages,
      detectedLanguages // TODO: detect languages
    )
    .then(() => {
      // TODO: navigation to the specific snipp page
      navigate('/');
    })
    .catch((error) => {
      setLoading(false);
      alert(error.message);
    });
  }, [duration, title, description, tags, sound, sticker, countryCode, creatorLanguages, detectedLanguages, navigate]);

  const updateTitle = useCallback((e) => setTitle(e.target.value), []);
  const updateDescription = useCallback((e) => setDescription(e.target.value), []);
  const updateTag = useCallback((e, v) => setTags(v), []);

  const tagPlaceholder = useMemo(() => {
    if (tags.length === 0) {
      return 'Enter at least 3 tags...'
    }
    if (tags.length === 1) {
      return '2 more...'
    }
    if (tags.length === 2) {
      return '1 more...'
    }
    return 'more?'
  }, [tags]);

  return (
    <StyledCreateContainer data-flex data-container>
      <h1>Publish</h1>
      <Snipp image={sticker.url} sound={sound.url} />
      <br/>
      <hr/>
      <br/>
      <TextField
        color='secondary'
        size='medium'
        id='title'
        label='Title'
        placeholder='A kickass title for your snipp'
        variant='outlined'
        fullWidth
        margin='normal'
        multiline
        maxRows={1}
        onChange={updateTitle}
        error={!!errorTitle}
        helperText={errorTitle}
        required
        disabled={loading}
      />
      <br/>
      <TextField
        color='secondary'
        size='medium'
        id='description'
        label='Description'
        placeholder='Describe your creation'
        variant='outlined'
        fullWidth
        multiline
        minRows={2}
        margin='normal'
        onChange={updateDescription}
        error={!!errorDescription}
        helperText={errorDescription}
        disabled={loading}
      />
      <br/>
      <Autocomplete
        color='secondary'
        className='clear'
        multiple
        id="tags"
        options={[]}
        defaultValue={[]}
        freeSolo
        onChange={updateTag}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            className='clear'
            style={{ display: 'block' }}
            variant="outlined"
            label="Tags"
            placeholder={tagPlaceholder}
            margin='normal'
            color='secondary'
            error={!!errorTags}
            helperText={errorTags}
            required
            disabled={loading}
          />
        )}
      />
      <div style={{ flex: 1 }} />
      <StyledCreateContinueButton data-flex data-disabled={loading} onClick={handleSubmit}>
        { loading ?
          <CircularProgress color='secondary' />
        :
          <h2>
            Publish
          </h2>
        }
      </StyledCreateContinueButton>
    </StyledCreateContainer>
  );
}

function CreateStage1({ onResult }) {
  const [sound, setSound] = useState(null);
  const [image, setImage] = useState(null);
  const [shouldRemoveBackground, setShouldRemoveBackground] = useState(true);

  const [noBgImageURL, setNoBgImageUrl] = useState(null);
  const [editedImageURL, setEditedImageURL] = useState(null);
  const [stickerText, setStickerText] = useState('');

  useEffect(() => {
    let cancel = false;

    async function prcoessSticker() {
      var withoutBG = image.url;
      withoutBG = await removeImageBackground(image.url);
      //withoutBG = await removeBackgroundFromImage(image.url);

      if (cancel) {
        return;
      }
      setNoBgImageUrl(withoutBG);
    }

    if (image?.url) {
      prcoessSticker();
    }

    return () => {
      cancel = true;
      setNoBgImageUrl(null);
    }
  }, [image]);

  useEffect(() => {
    let cancel = false;

    async function prcoessSticker() {
      const img = shouldRemoveBackground ? noBgImageURL : image?.url;
      const withText = await applyTextToImage(img, stickerText, '#fff');
      if (cancel) {
        return;
      }
      setEditedImageURL(withText);
    }

    if (noBgImageURL) {
      prcoessSticker();
    }

    return () => {
      cancel = true;
      setEditedImageURL(null);
    }
  }, [noBgImageURL, image?.url, shouldRemoveBackground, stickerText]);

  const handleSubmit = useCallback(() => {
    onResult?.({
      sticker: {
        fileName: image.name,
        url: editedImageURL,
        props: {
          title: stickerText,
          titleColor: '#ffffff',
        },
        sourceType: image.sourceType,
        sourceUrl: image.sourceUrl,
        sourceMetadata: { ...image.sourceMetadata },
      },
      sound: {
        fileName: sound.name,
        url: sound.url,
        sourceType: sound.sourceType,
        sourceUrl: sound.sourceUrl,
        sourceMetadata: { ...sound.sourceMetadata },
      },
    });
  }, [stickerText, editedImageURL, sound, image, onResult]);

  return (
    <StyledCreateContainer data-flex data-container>
      <h1>Create a Snipp</h1>
      <h2>Add Sound</h2>
      <p>(Up to 5 seconds)</p>
      <SoundChoice sound={sound} onSoundChange={setSound} />
      <h2>Add Sticker</h2>
      <p>(Image)</p>
      <ImageChoice image={image} onImageChange={setImage} />
      <br/>
      <FormControlLabel
          control={
            <Switch
              checked={shouldRemoveBackground}
              onChange={(e) => setShouldRemoveBackground(e.target.checked)}
            />
          }
          label="Remove Background"
        />
      <br/>
      <TextField
        label='Add sticker text (Optional)'
        variant='outlined'
        fullWidth={false}
        size='medium'
        onChange={(e) => setStickerText(e.target.value)}
        inputProps={{ maxLength: config.sticker.text.maxLength }}
        multiline
      />
      <hr/>
      <br/>
      { image && sound ? 
        <>
          <Snipp image={editedImageURL} sound={sound?.url} />
          <div data-flex style={{flex: 1}} />
          <StyledCreateContinueButton data-flex onClick={handleSubmit}>
            <h2>
              Continue
            </h2>
          </StyledCreateContinueButton>
        </>
      : null}
    </StyledCreateContainer>
  );
}

function SoundChoice({ sound, onSoundChange }) {
  const [file, setFile] = useState(null);
  const base64 = useFileAsBase64(file, true);
  const dataRef = useRef(null);

  useEffect(() => {
    if (!base64) {
      return;
    }

    var cancel = false;
    getAudioDuration(base64).then((duration) => {
      if (duration > 5 && !cancel) {
        setFile(null);
        onSoundChange?.(null);
        dataRef.current = null;
        alert(`Uploaded audio duration exceeds the 5 seconds limit (${duration})`)
      }
    });

    onSoundChange?.({
      ...dataRef.current,
      sourceType: dataRef.current instanceof File ? 'upload' : 'search',
      sourceUrl: dataRef.current.sound || '',
      sourceMetadata: dataRef.current instanceof File ? { filename: dataRef.current.name} : dataRef.current,
      url: base64,
      name: dataRef.current.name,
    });

    return () => {
      cancel = true;
    }
   }, [base64, onSoundChange]);

  const handleInput = useCallback((e) => {
    const files = e.target.files;

    if (files.length <= 0) {
      throw new Error('No files returned?');
    }

    dataRef.current = files[0];
    setFile(files[0]);
  }, []);

  const handleSearch = useCallback((item) => {
    dataRef.current = item;
    setFile(item?.sound);
  }, []);

  return (
    <StyledCreateAddStickerInputSection data-flex>
      <SoundSearchMemo style={{flex: 1}} value={sound} onChange={handleSearch} />
      <StyledCreateAddStickerInputSectionSpan>or</StyledCreateAddStickerInputSectionSpan>
      <Button variant='contained' size='large' component='label'>
        <StyledCreateUploadButton>
          <MdUploadFile size='25' />
        </StyledCreateUploadButton>
        <input hidden id='sound-input' type='file' accept='audio/mp3,audio/wav' onChange={handleInput} />
      </Button>
    </StyledCreateAddStickerInputSection>
  );
}

export function ImageSearch({style, value, onChange, placeholder}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  // const loading = open && options.length === 0;
  const [loading, setLoading] = React.useState(false);
  const [searchText, setSearchText] = useState('');
  const [option, setOption] = useState(null);

  useEffect(() => setOption(value), [value]);

  React.useEffect(() => {
    let active = true;

    setOptions([]);

    if (searchText.length == 0) {
      return undefined;
    }

    setLoading(true);

    setTimeout(() => {
      if (!active) {
        return;
      }

      searchImages(searchText)
      .then((res) => {
        if (active && res) {
          setOptions(res);
        }
      })
      .finally(() => {
        if (active) {
          setLoading(false);
        }
      });
    }, 1000);

    return () => {
      active = false;
      setLoading(false);
    };
  }, [searchText]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleChange = useCallback((event, value) => {
    setOption(value);
    onChange?.(value);
  }, [onChange]);

  return (
    <Autocomplete
      sx={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      style={style}
      value={option}
      isOptionEqualToValue={(option, value) => option.thumbnail === value.thumbnail}
      getOptionLabel={(option) => option.title}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.thumbnail}>
          <img src={option.thumbnail} style={{ height: '3em' }}/>
          { option.title }
        </li>
      )}
      options={options}
      loading={loading}
      onChange={handleChange}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label={ placeholder ? null : "Search the web for images" }
          onChange={(e) => setSearchText(e.target.value)}
          fullWidth
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              option ?
                <img src={option.thumbnail} style={{ height: '3em', width: '3em' }}/>
              : null
            ),
            endAdornment: (
              <React.Fragment>
                {loading ?
                  <CircularProgress color="inherit" size={20} />
                : null}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

function ImageChoice({ image, onImageChange }) {
  const [search, setSearch] = useState(null);
  const [file, setFile] = useState(null);
  const base64 = useFileAsBase64(file, false);
  const dataRef = useRef(null);

  useEffect(() => {
    if (!base64) {
      return;
    }

    onImageChange?.({
      url: base64,
      sourceType: dataRef.current instanceof File ? 'upload' : 'search',
      sourceUrl: dataRef.current?.original || '', // TODO: original?
      sourceMetadata: {
        ...dataRef.current,
      },
      name: dataRef.current.name ?? dataRef.current.title,
    });
   }, [base64, onImageChange]);

  const handleInput = useCallback((e) => {
    const files = e.target.files;

    if (files.length <= 0) {
      throw new Error('No files returned?');
    }

    dataRef.current = files[0];
    setFile(files[0]);
    setSearch(null);
  }, []);

  const handleSearch = useCallback((item) => {
    dataRef.current = item;
    setSearch(item);
    setFile(item.original);
  }, []);

  return (
    <StyledCreateAddStickerInputSection data-flex>
      <ImageSearch placeholder={file?.name} style={{flex: 1}} value={search} onChange={handleSearch} />
      <StyledCreateAddStickerInputSectionSpan>or</StyledCreateAddStickerInputSectionSpan>
      <Button variant='contained' size='large' component='label'>
        <StyledCreateUploadButton>
          <MdUploadFile size='25' />
        </StyledCreateUploadButton>
        <input hidden id='image-input' type='file' accept='image/png,image/jpeg' onChange={handleInput} />
      </Button>
    </StyledCreateAddStickerInputSection>
  );
}

function SoundListItem({ text, source, props }) {
  const audio = useMemo(() => new Audio(source), [source]);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    var cancel = false;

    audio.ondurationchange = () => {
      if (!cancel) {
        setDuration(audio.duration);
      }
    }

    return () => {
      cancel = true;
    }
  }, [audio]);

  const handleClick = useCallback((e) => {
    audio.currentTime = 0;
    audio.play();
    props?.onClick?.(e);
  }, [audio, props]);

  const handleEnter = useCallback((e) => {
    audio.currentTime = 0;
    audio.play();
  }, [audio]);
  
  return ( duration && duration <= 5 ? (
    <li {...props} onClick={handleClick} onMouseEnter={handleEnter}>
      <MdPlayArrow />
      { text }
    </li>
  ) : null
  );
}

export const SoundSearchMemo = React.memo(SoundSearchRaw);
function SoundSearchRaw({style, value, onChange}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [searchText, setSearchText] = useState('');
  const [option, setOption] = useState(null);

  useEffect(() => setOption(value), [value]);

  React.useEffect(() => {
    let active = true;

    setOptions([]);

    if (searchText.length === 0) {
      return undefined;
    }

    setLoading(true);

    setTimeout(() => {
      if (!active) {
        return;
      }

      searchSounds(searchText)
      .then((res) => {
        if (active) {
          setOptions(res || []);
        }
      })
      .finally(() => {
        if (active) {
          setLoading(false);
        }
      });
    }, 600);

    return () => {
      active = false;
      setLoading(false);
    };
  }, [searchText]);

  React.useEffect(() => {
    if(!open) {
      setOptions([]);
    }
  }, [open]);

  const handleChange = useCallback((event, value) => {
    setOption(value);
    onChange?.(value);
  }, [onChange]);

  return (
    <Autocomplete
      sx={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={(e) => {
        setOpen(false);
      }}
      disableCloseOnSelect
      style={style}
      value={option}
      isOptionEqualToValue={(option, value) => option.sound === value.sound}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <SoundListItem key={option.sound} text={option.name} source={option.sound} props={props} />
      )}
      options={options}
      loading={loading}
      onChange={handleChange}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search for audio"
          onChange={(e) => { setSearchText(e.target.value); }} 
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ?
                  <CircularProgress color="inherit" size={20} />
                : null}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}