import React from 'react';

import pdf from '../../assets/documents/privacy.pdf';
// import pdf from 'src/assets/documents/copyright.pdf';

export function Privacy() {
  return (
    <iframe src={`${pdf}?embedded=true`} width='100%' height='100%'/>
  );
}
