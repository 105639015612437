import React, { useRef, useState, useEffect, useCallback } from 'react';
import logo from '../../assets/images/snipps.png';
import { MdSearch } from 'react-icons/md';

import algoliasearch from 'algoliasearch/lite';
import { config } from 'src/config';
import { Snipp } from 'src/components/snipp/snipp';
import { StyledHeader, StyledHeaderCenter, StyledHeaderCreateButton, StyledHeaderCreateButtonText, StyledHeaderLogo, StyledHomeContainer, StyledHomeSearch, StyledHomeSearchIcon, StyledHomeSearchInput, StyledHomeSnipps } from './home.styled';

export const searchSnipps = async (search: string) => {
  const client = algoliasearch(config.search.snipps.applicationId, config.search.snipps.apiKey);
  const index = client.initIndex(config.search.snipps.index);

  // Algolia not typed correctly, returns path as well from search
  const results = await index.search(search, {
    length: 50,
  });

  return results.hits;
};

export function Home({}) {
  return (
    <StyledHomeContainer data-flex>
      <HomeHeader />
      <HomeContent />
      <HomeFooter />
    </StyledHomeContainer>
  );
}

export function HomeHeader() {
  return (
    <StyledHeader data-flex>
      <StyledHeaderLogo data-flex src={logo}/>
      <StyledHeaderCenter data-flex />
      <StyledHeaderCreateButton data-flex to='/create'>
        <StyledHeaderCreateButtonText data-flex>
          Create a Snipp
        </StyledHeaderCreateButtonText>
      </StyledHeaderCreateButton>
    </StyledHeader>
  );
}

export function HomeContent() {
  const [searchterm, setSearchterm] = useState('');
  const [snipps, setSnipps] = useState([]);

  const handleSearchInput = useCallback((e) => setSearchterm(e.target.value || ''), []);

  useEffect(() => {
    let cancel = false;

    searchSnipps(searchterm).then((results) => {
      if (cancel) return;
      
      setSnipps(results);
    });

    return () => {
      cancel = true;
    }
  }, [searchterm]);

  return (
    <div data-flex>
      <StyledHomeSearch data-flex className='search'>
        <StyledHomeSearchIcon data-flex className='search-icon'>
          <MdSearch color='#1a1a1a' size={'2.5em'} />
        </StyledHomeSearchIcon>
        <StyledHomeSearchInput type='text' placeholder='Search' onChange={handleSearchInput} />
      </StyledHomeSearch>
      <StyledHomeSnipps data-flex>
        { snipps.map(({ objectID, audioUrl, stickerImageUrl, creator, duration }) => <Snipp sound={audioUrl} image={stickerImageUrl} />) }
      </StyledHomeSnipps>
    </div>
  );
}

export function HomeFooter() {
  return (
    <div data-flex className='footer'></div>
  );
}