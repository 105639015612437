import { useEffect, useState } from "react";
import { wrapURLWithPasten } from "./utils";

export async function getAudioDuration(source): Promise<number> {
  const url = wrapURLWithPasten(source); 
  const audio = new Audio();
  const promise = new Promise((resolve) => audio.addEventListener('loadedmetadata', resolve));
  audio.src = url;
  await promise;
  
  const duration = audio.duration;
  audio.remove();
  return duration;
}

export function useAudioDuration(source): number {
  const [duration, setDuration] = useState<number>(null);

  useEffect(() => {
    let cancel = false;

    getAudioDuration(source)
    .then((result) => {
      if (cancel) {
        return;
      }

      setDuration(result);
    });
    return () => {
      cancel = true;
      setDuration(null);
    }
  }, [source]);

  return duration;
}