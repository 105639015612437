import styled, { css } from 'styled-components'
import { StyledButton, StyledContainer } from 'src/app.styled';

export const StyledLoginContainer = styled(StyledContainer)`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const StyledLoginButton = styled(StyledButton)`
  font-size: 1.2em;
  font-weight: 600;
  padding: 1em 1.5em;
  border-radius: 5px;
`;