import styled, { css } from 'styled-components'
import { StyledButton, StyledContainer } from 'src/app.styled';

export const StyledCreateContainer = styled(StyledContainer)`
  flex: 1;
`;

export const StyledCreateSoundChoice = styled.div`
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  height: 4.0vw;
`;

export const StyledCreateUploadButton = styled.div`
  height: 2.5em;
  width: 2.5em;
  text-align: center;
  display: flex;  
  align-items: center;
  justify-content: center;
`;

export const StyledCreateSoundChoiceText = styled.div`
  margin: 0;
`;

export const StyledCreateInput = styled.input`
  display: none;
`;

export const StyledCreateContinueButton = styled(StyledButton)`
  align-items: center;
  border-radius: 5px;
  margin: 1em 0;
  height: 3.5em;
  justify-content: center;
`;

export const StyledCreateAddStickerInputSection = styled.div`
  flex-direction: row;
  align-items: center;
`;

export const StyledCreateAddStickerInputSectionSpan = styled.span`
  padding: 0 1em;
  font-weight: 300;
`;