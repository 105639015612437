import styled, { css } from 'styled-components'
import { StyledContainer } from 'src/app.styled';
import { Link } from 'react-router-dom';

export const StyledHomeContainer = styled(StyledContainer)`
  flex: 1;
  padding-bottom: 2em;
`;

export const StyledHeader = styled.div`
  flex-direction: row;
  height: 3.5em;
  align-items: center;
  margin: 2em 0;
`;

export const StyledHeaderLogo = styled.img`
  flex-direction: row;
  height: 3.5em;
  align-items: center;
  margin: 2em 0;
`;

export const StyledHeaderCenter = styled.div`
  flex: 1;
`;

export const StyledHeaderCreateButton = styled(Link)`
  background-color: #DA315C;
  height: 100%;
  padding: 0 2em;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
`;

export const StyledHeaderCreateButtonText = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  color: #fafafa;
`;

export const StyledHomeSearch = styled.div`
  color: #1a1a1a;
  height: 3.5em;
  background-color: #E8E8E8;
  border-radius: 5px;
  flex-direction: row;
`;

export const StyledHomeSearchIcon = styled.div`
  align-items: center;
  justify-content: center;
  width: 4em;
`;

export const StyledHomeSearchInput = styled.input`
  all: unset;
  font-size: 1.3em;
  font-weight: 300;
  flex: 1;
`;

export const StyledHomeSnipps = styled.div`
  flex: 1;
  flex-direction: row;
  gap: 2em;
  margin-top: 2em;
  flex-wrap: wrap;
`;