import './App.css';
import background from 'src/assets/images/bg.jpg'
import React, { useEffect } from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { Home } from './pages/home/home';
import { Create } from './pages/create/create';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { colors } from 'src/styles/colors';
import { getUsername, useCurrentUser, useCurrentUsername } from 'src/libs/user';
import { Login } from 'src/pages/login/login';
import { getAuth, signOut } from 'firebase/auth';
import { Setup } from 'src/pages/setup/setup';
import { Loading } from 'src/pages/loading/loading';
import { Copyright } from 'src/pages/copyright/copyright';
import { Privacy } from 'src/pages/privacy/privacy';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: colors.hotpink,
    },
    secondary: {
      main: colors.flamingo,
    },
  },
});


function AppSignout() {
  useEffect(() => {
    const auth = getAuth();
    signOut(auth);
  }, []);

  return <></>;
}

function App() {
  const user = useCurrentUser();
  const username = useCurrentUsername();

  useEffect(() => {
    if (!user) return;
    getUsername().then(console.log)
  }, [user]);

  useEffect(() => {
    if (user && user.email && !user.email.endsWith('@voystory.com')) {
      const auth = getAuth();
      signOut(auth);
    }
  }, [user]);

  return (
    <ThemeProvider theme={theme}>
      <div data-flex className="App" style={{ backgroundImage: `url(${background})` }}>
        <Router>
          <Routes>
            <Route path='/privacy' element={<Privacy />} />
            <Route path='/copyright' element={<Copyright />} />
            { user === null || (user && username === null) ?
              <Route path='*' element={<Loading />} />
            : (!user || !user.email.endsWith('@voystory.com')) ?
              <Route path='*' element={<Login />} />
            : (!username) ?
              <Route path='*' element={<Setup />} />
            :
              <>
                <Route path='/' element={<Home />} />
                <Route path='/create' element={<Create />} />
                <Route path='/signout' element={<AppSignout />} />
              </>
            }
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
