import styled, { css } from 'styled-components'
import { CONTAINER_PADDING_DESKTOP, CONTAINER_PADDING_MOBILE } from 'src/app.styled';

export const AMOUNT_OF_SNIPPS_PER_ROW_DESKTOP = 5;
export const AMOUNT_OF_SNIPPS_PER_ROW_MOBILE = 2;

export const StyledSnipp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 5px;

  &[data-playing=true],
  &:hover {
    background-color: rgba(255, 255, 255, 0.10);
    cursor: pointer;
  };
  &[data-playing=true] {
    background-color: #DA315C;
  };

  & > img:hover,
  & > img[data-playing=true],
  &:hover > img {
    max-height: 80%;
    max-width: 80%;
    cursor: pointer;
  }

  // Mobile
  @media only screen and (max-width: 768px) {
    width: calc(((100vw - (2 * ${CONTAINER_PADDING_MOBILE})) / ${AMOUNT_OF_SNIPPS_PER_ROW_MOBILE}) - 2em / ${AMOUNT_OF_SNIPPS_PER_ROW_MOBILE});
    height: calc(((100vw - (2 * ${CONTAINER_PADDING_MOBILE})) / ${AMOUNT_OF_SNIPPS_PER_ROW_MOBILE}) - 2em / ${AMOUNT_OF_SNIPPS_PER_ROW_MOBILE});
  }
  // Desktop
  @media only screen and (min-width: 768px) {
    width: calc(((100vw - (2 * ${CONTAINER_PADDING_DESKTOP})) / ${AMOUNT_OF_SNIPPS_PER_ROW_DESKTOP}) - 8em / ${AMOUNT_OF_SNIPPS_PER_ROW_DESKTOP});
    height: calc(((100vw - (2 * ${CONTAINER_PADDING_DESKTOP})) / ${AMOUNT_OF_SNIPPS_PER_ROW_DESKTOP}) - 8em / ${AMOUNT_OF_SNIPPS_PER_ROW_DESKTOP});
  }
`;

export const StyledSnippImage = styled.img`
  pointer-events: none;
  -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -o-user-select: none; user-select: none; -webkit-user-drag: none; -khtml-user-drag: none; -moz-user-drag: none; -o-user-drag: none; user-drag: none;
  filter: drop-shadow(0 0 5px #000);
  max-height: 65%;
  max-width: 65%;
  transform: rotateY(0deg) rotate(-10deg);
`;
