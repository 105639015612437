import React, { useRef, useState, useEffect, useCallback } from 'react';
import './loading.css';
import icon from '../../assets/images/icon.png';

export function Loading({}) {
  return (
    <div data-flex className='loading-container'>
      <img src={icon} width='300px' height='300px' />
    </div>
  );
}
