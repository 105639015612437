import styled, { css } from 'styled-components'

export const CONTAINER_PADDING_DESKTOP = '15vw';
export const CONTAINER_PADDING_MOBILE = '5vw';

export const StyledButton = styled.div`
  background-color: #DA315C;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }

  &[data-disabled=true] { background-color: #666; cursor: unset; opacity: 0.5 }
  &[data-disabled=true]:hover { opacity: 0.5 };
`;

export const StyledContainer = styled.div`
  /* Mobile */
  @media only screen and (max-width: 768px) {
    margin: 0 ${CONTAINER_PADDING_MOBILE};
  }
  /* Desktop */
  @media only screen and (min-width: 768px) {
    margin: 0 ${CONTAINER_PADDING_DESKTOP};
  }
`;