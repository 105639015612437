export const config = {
  storage: {
    bucket: {
      public: {
        path: 'gs://snipps',
        name: 'snipps',
      },
    },
  },
  search: {
    snipps: {
      applicationId: 'IA5LU6IAOU',
      apiKey: '759faef7aab7f96ab140a54aca7008df',
      index: 'snipps',
    },
  },
  sticker: {
    text: {
      maxLength: 18,
    },
  },
};