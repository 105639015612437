import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';

import pdf from '../../assets/documents/copyright.pdf';
// import pdf from 'src/assets/documents/copyright.pdf';

export function Copyright() {
  return (
    <iframe src={`${pdf}?embedded=true`} width='100%' height='100%'/>
  );
}
