import styled, { css } from 'styled-components'
import { StyledButton, StyledContainer } from 'src/app.styled';

export const StyledSetupContainer = styled(StyledContainer)`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const StyledClaimUsernameButton = styled(StyledButton)`
  font-size: 1.2em;
  font-weight: 600;
  padding: 0.8em 0;
  border-radius: 5px;
  margin: 1em 0;
  width: 8em;
  align-items: center;
  justify-content: center;
`;