import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import icon from '../../assets/images/icon.png';
import { useCurrentUser } from 'src/libs/user';
import { Navigate } from 'react-router-dom';
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { StyledLoginButton, StyledLoginContainer } from './login.styled';

export function Login({}) {
  const googleProvider = useMemo(() => new GoogleAuthProvider(), []);
  const user = useCurrentUser();

  const loginWithGoogle = useCallback(() => {
    const auth = getAuth();
    signInWithPopup(auth, googleProvider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      // ...
    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
      alert(errorMessage);
    });
  }, [googleProvider]);

  return (
    <StyledLoginContainer data-flex data-container>
      { user === false ?
        <StyledLoginButton onClick={loginWithGoogle}>
          Continue with Google
        </StyledLoginButton>
      :
        <Navigate to='/' />
      }
      <br/>
      <p>This site is private, for more info contact amit@snipps.co or try our <u><a href='https://ve.snipps.co/'>Video Editor</a></u></p>
    </StyledLoginContainer>
  );
}