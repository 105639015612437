import React, {useState, useEffect, useMemo} from 'react';

import { getAuth } from 'firebase/auth';
import type { User } from 'firebase/auth';
import { collection, getFirestore, onSnapshot, query, where } from "firebase/firestore";

export const useCurrentUser = () : User | false => {
  const [user, setUser] = useState<User | false>(getAuth().currentUser);

  useEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged((_user) => {
      if (_user) {
        setUser(_user);
      }
      else {
        setUser(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return user;
};

export const useCurrentUid = (): string | null => {
  const user = useCurrentUser();

  return useMemo(() => (user && user.uid) ?? null, [user]);
};

export const useCurrentUsername = () => {
  const [name, setName] = useState<string | false>(null);
  const uid = useCurrentUid();

  useEffect(() => {
    if (!uid) {
      return;
    }

    let cancel = false;

    const firestore = getFirestore();
    const q = query(collection(firestore, "user"), where("uid", "==", uid));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      if (cancel) {
        return;
      }

      if (snapshot.docs.length > 0) {
        setName(snapshot.docs[0].id);
      } else {
        setName(false);
      }
    });

    return () => {
      unsubscribe();
      cancel = true;
    };
  }, [uid]);

  return name;
}

export const getUsername = async (): Promise<string> => {
  const token = await getAuth().currentUser.getIdTokenResult(true);

  return token.claims.username as string;
}